const state = {
  currentClient: null,
};

const mutations = {
  updateClient(nextState, client){
    nextState.currentClient = client;
  },
};

const actions =  {
  setCurrentClient: (context, client) => context.commit('updateClient', client),
  removeCurrentClient: (context) => context.commit('updateClient', null),
};

export default {
  state,
  actions,
  mutations,
};
