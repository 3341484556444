const state = {
    currentService: null,
  };
  
  const mutations = {
    updateService(nextState, service){
      nextState.currentService = service;
    },
  };
  
  const actions =  {
    setCurrentService: (context, service) => context.commit('updateService', service),
    removeCurrentService: (context) => context.commit('updateService', null),
  };
  
  export default {
    state,
    actions,
    mutations,
  };
  