import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },

  //Orders
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/order/Orders.vue')
  },
  {
    path: '/orders/sales',
    name: 'SalesOrders',
    component: () => import('../views/order/SalesOrders.vue')
  },
  {
    path: '/order/add',
    name: 'CreateOrder',
    component: () => import('../views/order/CreateOrder.vue')
  },
  {
    path: '/order/:id',
    name: 'Order',
    component: () => import('../views/order/Order.vue')
  },

  //Products
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/product/Products.vue')
  },
  {
    path: '/product/add',
    name: 'AddProduct',
    component: () => import('../views/product/AddProduct.vue')
  },
  {
    path: '/product/edit/:id',
    name: 'EditProduct',
    component: () => import('../views/product/EditProduct.vue')
  },
  {
    path: '/categories',
    name: 'Categories',
    component: () => import('../views/product/Categories.vue')
  },
  {
    path: '/categorie/add',
    name: 'AddCategorie',
    component: () => import('../views/product/AddCategorie.vue')
  },
  {
    path: '/categorie/edit/:id',
    name: 'EditCategorie',
    component: () => import('../views/product/EditCategorie.vue')
  },
  {
    path: '/barcodes',
    name: 'Barcode',
    component: () => import('../views/product/Barcode.vue')
  },

  //Inventory
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import('../views/inventory/Inventory.vue')
  },

  //Document
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('../views/document/Documents.vue')
  },
  {
    path: '/document/add',
    name: 'CreateDocument',
    component: () => import('../views/document/CreateDocument.vue')
  },

  //Customers
  {
    path: '/customers',
    name: 'Customers',
    component: () => import('../views/customer/Customers.vue')
  },
  {
    path: '/customer/:id',
    name: 'Customer',
    component: () => import('../views/customer/Customer.vue')
  },

  //Promotions
  {
    path: '/promotions',
    name: 'Promotions',
    component: () => import('../views/promotion/Promotions.vue')
  },

  //Comments
  {
    path: '/comments',
    name: 'Comments',
    component: () => import('../views/comment/Comments.vue')
  },

  //Reports
  {
    path: '/report/inventory',
    name: 'InventoryReport',
    component: () => import('../views/report/InventoryReport.vue')
  },
  {
    path: '/report/date',
    name: 'Report',
    component: () => import('../views/report/DateReport.vue')
  },
  {
    path: '/report/popular',
    name: 'MorePopularReport',
    component: () => import('../views/report/MostPopular.vue')
  },
  {
    path: '/report/product',
    name: 'PerPrdouctReport',
    component: () => import('../views/report/ProductReport.vue')
  },

  //SNS
  {
    path: '/sns/facebook',
    name: 'Facebook',
    component: () => import('../views/sns/Facebook.vue')
  },

  //Content
  {
    path: '/content',
    name: 'Content',
    component: () => import('../views/content/Content.vue')
  },

  //Client
  {
    path: '/clients',
    name: 'Clients',
    component: () => import('../views/account/Clients.vue')
  },
  {
    path: '/client/add',
    name: 'CreateClient',
    component: () => import('../views/account/CreateClient.vue')
  },
  {
    path: '/client/:id',
    name: 'Client',
    component: () => import('../views/account/Client.vue')
  },

  //Services
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/service/Services.vue')
  },
  {
    path: '/services/add',
    name: 'CreateService',
    component: () => import('../views/service/CreateService.vue')
  },
  {
    path: '/service/:id',
    name: 'Service',
    component: () => import('../views/service/Service.vue')
  },
  {
    path: '/services/orders',
    name: 'ServiceOrders',
    component: () => import('../views/service/ServiceOrders.vue')
  },
  {
    path: '/services/order/add',
    name: 'CreateServiceOrder',
    component: () => import('../views/service/CreateServiceOrder.vue')
  },
  {
    path: '/services/order/:id',
    name: 'ServiceOrder',
    component: () => import('../views/service/ServiceOrder.vue')
  },

  //Settings
  {
    path: '/settings/shop',
    name: 'ShopSettings',
    component: () => import('../views/setting/ShopSettings.vue')
  },

  {
    path: '/settings/backup',
    name: 'Backup',
    component: () => import('../views/backup/Backup.vue')
  },
  {
    path: '/settings/sns',
    name: 'SnsSettings',
    component: () => import('../views/setting/SnsSettings.vue')
  },
  {
    path: '/settings/payment',
    name: 'PaymentSettings',
    component: () => import('../views/setting/PaymentSettings.vue')
  },
  {
    path: '/settings/account',
    name: 'AccountSettings',
    component: () => import('../views/setting/AccountSettings.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
