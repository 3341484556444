<template>
  <div class="topbar">
    <header class="main-header" id="header">
      <nav class="navbar navbar-expand-md navbar-dark bg-primary">
        <div class="hamburger-lines" @click="controlSidebar">
          <span class="line line1"></span>
          <span class="line line2"></span>
          <span class="line line3"></span>
        </div>
        <div class="navbar-language-switcher">
          <el-dropdown trigger="click" @command="selectLang">
            <span class="el-dropdown-link">
              <span v-if="langcode === 'en'">
                English<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <span v-if="langcode === 'zh'">
                繁體中文<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="en">English</el-dropdown-item>
              <el-dropdown-item command="zh">繁體中文</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="#">{{ $t('View My Shop') }}</a>
            </li>
            <li class="nav-item">
              <el-dropdown trigger="click" @command="handleAction">
                <span class="el-dropdown-link">
                  User<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>{{ $t('Account Setting') }}</el-dropdown-item>
                  <el-dropdown-item command="logout">{{ $t('Logout') }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Cookies from 'js-cookie';

export default {
  name: 'Topbar',
  data(){
    return {
      displaySidebar: false,
    }
  },
  methods: {
    controlSidebar(){
      const sidebar = document.querySelector('.sidemenu');
      const body = document.querySelector('.right-content');
      if(!this.displaySidebar){
        this.displaySidebar = true;
        sidebar.classList.add('display-mobile');
        body.classList.add('openmenu');
      }else{
        this.displaySidebar = false;
        sidebar.classList.remove('display-mobile');
        body.classList.remove('openmenu');
      }
    },
    selectLang(langcode) {
      Cookies.set('langcode', langcode);
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Admin Panel Title');
    },
    handleAction(command) {
      switch (command) {
        case 'logout':
          this.logout();
          break;
      }
    },
    logout() {
      Cookies.remove('loginToken');
      Cookies.remove('userId');
      this.startLogout();
      this.$router.push('/');
    },
    ...mapActions(['startLogout', 'changeLang']),
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.topbar {
  position: sticky;
  top: 0;
  z-index: 50;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.nav-item {
  margin-right: 20px;

  &:last-child {
    margin-left: 20px;
  }
}

.logo {
  width: 40px;
}

.navbar-placeholder {
  flex: 1;
  color: white;
}

.el-dropdown-link {
  color: white;

  &:hover {
    cursor: pointer;
  }
}

#collapsibleNavbar {
  justify-content: flex-end;

  .navbar-nav {
    display: flex;
    align-items: center;

    a {
      color: white;
    }
  }
}

.hamburger-lines {
  height: 20px;
  width: 26px;
  z-index: 2;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 992px){
    display: flex;
  }
}

.hamburger-lines .line {
  display: block;
  height: 3px;
  width: 100%;
  border-radius: 10px;
  background: white;
}

.hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}
</style>
