import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementUI from 'element-ui';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-TW';
import 'element-ui/lib/theme-chalk/index.css';
import langFile from "./lang.json";

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.use(VueI18n);

const messages = {
  en: {
    ...langFile.en,
    ...enLocale
  },
  zh: {
    ...langFile.zh,
    ...zhLocale
  }
}

const i18n = new VueI18n({
  locale: 'zh',
  messages,
})

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
